import React from 'react';

import { Col, Row, } from 'reactstrap';

export default function MainAboutSection() {
    return (
        <section className="d-flex flex-column justify-content-end pl-4 pb-4" style={{
            width: "100vw",
            height: "100vh",
            position: "relative",
            overflow: "hidden",
        }}>
            <div
                style={{
                    backgroundImage: `url(${require("assets/img/professor-marcelo/1.png")})`,
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: "-2"
                }}
            />
            <div
                style={{
                    backgroundColor: "#000",
                    opacity: .8,
                    backgroundAttachment: "fixed",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100%",

                }}
            />

            <Row className="text-white " >
                <Col >
                    <h2>UM APAIXONADO PELA EDUCAÇÃO</h2>
                    <h3 className="font-weight-bold mb-4" > Professor Marcelo da Silva</h3>
                </Col>
            </Row>


        </section>
    )
}