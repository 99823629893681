

import React from 'react';
import { useHistory } from 'react-router';
import { Button, Col, Container, Row } from 'reactstrap';

export default function ClassSection(props) {
    const aula = props.history.location.state.aula;
    const history = useHistory()
    console.log("AULA", aula)
    return (
        <Container fluid>
            <Row>
                <Col>
                    <Row className="align-items-center mt-4" >
                        <i style={{ fontSize: 24, cursor: "pointer" }} onClick={() => history.goBack()} class="fa fa-arrow-left"></i>
                        <h2 className="font-weight-bold m-0 ml-4" > {aula.titulo} </h2>
                    </Row>
                    <hr />
                    <iframe src={aula.videoUrl} style={{ width: "100%", height: "50vh", backgroundColor: "#000" }} frameborder="0" allow="autoplay; fullscreen" allowfullscreen data-ready="true" />
                    <Row>
                        {
                            aula.descricao && (
                                <Col xs="12" sm="6" >
                                    <h3 className="font-weight-bold" >Descrição</h3>
                                    <p> {aula.descricao} </p>
                                </Col>
                            )
                        }
                        <Col className="text-center" >
                            <h4 className="font-weight-bold mb-2">Monitoria</h4>
                            <a title="Email" href="mailto:professormarcelo.com@gmail.com" target="_self" className="mr-2" >
                                <Button color="danger" className="btn-round" >
                                    <i class="fa fa-envelope"></i>
                                </Button>
                            </a>
                            <a title="telefone" href="tel:+5547984250399" target="_self" className="ml-2">
                                <Button color="danger" className="btn-round" >
                                    <i class="fa fa-phone"></i>
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}