

import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardImg, CardImgOverlay, Col, Row } from 'reactstrap';

function getImage() {
    switch (Math.random() * 10) {
        case 1:
            return require("../../../assets/img/img1.jpg");
        case 2:
            return require("../../../assets/img/img2.jpg");
        case 3:
            return require("../../../assets/img/img3.jpg");
        default:
            return require("../../../assets/img/img4.jpg");
    }
}


export default function ContentCard({aula}) {
    const history = useHistory()
    const match = useRouteMatch()

    return (
        <Link to={{
            pathname: `/modulos/aula`,
            state: {aula}
        }}>
            <Card  className="bg-dark text-white" style={{ cursor: "pointer", height: "144px" }}>
            <CardImg style={{ height: "100%", borderRadius: 16 }} src={getImage()} />
            <CardImgOverlay className="d-flex align-items-center justify-content-center" style={{ backgroundColor: "#000000a7", borderRadius: "16px" }}>
                <CardBody >
                    <Row>
                        <Col>
                            <h3 className="m-0 text-uppercase font-weight-bold text-center" >{aula.titulo}</h3>
                        </Col>
                    </Row>
                </CardBody>
            </CardImgOverlay>
        </Card>
        </Link>
    )
}