

import React, { useState } from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Container, UncontrolledCollapse, Button, Col } from 'reactstrap';
import { useMediaQuery } from 'react-responsive'
import classnames from "classnames";
import { useHistory } from 'react-router';
import {authLogout} from '../../../services/auth'

export default function VertcialNav({ modules, setActualModule }) {
  const history = useHistory()
  const [navbarCollapse, setNavbarCollapse] = useState(false);
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  function logout() {
    authLogout()
    history.push("/login")
  }

  function onModuleLinkClick(module){
    if(history.location.pathname != "/modulos/main") history.push({
      pathname: "/modulos/main",
      state: {id: history.location.state.id, nome: history.location.state.nome}
    })
    setActualModule(module);
    toggleNavbarCollapse()
    console.log("LOCATION:", history.location)
  }

  if (isTabletOrMobileDevice) {
    return (
      <Navbar className="bg-dark" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="#"
            >
              {modules[0].cursos[0].nome}
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={navbarCollapse}
          >
            <Nav navbar>
              <NavItem className="font-weight-bold text-uppercase" style={{ fontSize: 18 }} >
                Módulos
            </NavItem>
              {
                modules.map(module => (
                  <NavItem key={Date.now() + module.id} >
                    <NavLink className="mb-0 mt-2" href="#" onClick={()=> onModuleLinkClick(module)} > {module.nome} </NavLink>
                  </NavItem>
                ))
              }

              <NavItem className="d-flex justify-content-center mt-5" >
                <Button onClick={() => history.push("/selecionar-curso")} color="danger" className="btn-round" >
                  <i class="fa fa-book mr-2" ></i>
                  Escolher outro curso
                </Button>
              </NavItem>

              <NavItem className="d-flex justify-content-center">
                <Button onClick={()=> logout()} color="danger" className="btn-round w-50" outline>
                  Sair
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    )
  }

  return (
    <Navbar color="dark" className="text-white align-items-start" style={{ height: "100vh" }} >
      <Nav className="p-3 pt-4" vertical navbar >
        <NavItem className="font-weight-bold text-uppercase text-center mb-5" style={{ fontSize: 24 }} >
          {modules[0].cursos[0].nome}
        </NavItem>
        <NavItem className="font-weight-bold text-uppercase" style={{ fontSize: 18 }} >
          Módulos
            </NavItem>
        {
          modules.map(module => (
            <NavItem key={module.id}>
              <NavLink className="mb-0 mt-2" href="#" onClick={()=> onModuleLinkClick(module)} > {module.nome} </NavLink>
            </NavItem>
          ))
        }

        <NavItem className="d-flex justify-content-center mt-5" >
          <Button onClick={() => history.push("/selecionar-curso")} color="danger" className="btn-round" >
            <i class="fa fa-book mr-2" ></i>
            Escolher outro curso
          </Button>
        </NavItem>

        <NavItem className="d-flex justify-content-center">
          <Button onClick={() => history.push("/login")} color="danger" className="btn-round w-50" outline>
            Sair
          </Button>
        </NavItem>
      </Nav>
    </Navbar>
  )
}