import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

export default function BooksSection(props) {
  return (
    <Row className="mb-4 pb-4">
      <Col sm="12" md="6">
        <div style={{ height: "96vh", position: "relative" }}>
          <img
            style={{
              position: "absolute",
              height: "48vh",
              top: "10%",
              left: "12%",
            }}
            src={require("assets/img/professor-marcelo/20e65eef83d450b295000616a1dc04d5.jpg")}
          />
          <img
            style={{
              position: "absolute",
              height: "48vh",
              top: "20%",
              left: "24%",
            }}
            src={require("assets/img/professor-marcelo/ensinar.png")}
          />
          <img
            style={{
              position: "absolute",
              height: "48vh",
              top: "30%",
              left: "32%",
            }}
            src={require("assets/img/professor-marcelo/livropoder.jpeg")}
          />
          <img
            style={{
              position: "absolute",
              height: "48vh",
              top: "40%",
              left: "48%",
            }}
            src={require("assets/img/professor-marcelo/WhatsApp Image 2020-08-24 at 08.34.25.jpeg")}
          />
          <img
            style={{
              position: "absolute",
              height: "48vh",
              top: "48%",
              left: "56%",
            }}
            src={require("assets/img/professor-marcelo/o-professor-que-me-tornei.jpeg")}
          />
        </div>
      </Col>
      <Col className="d-flex flex-column justify-content-center p-4 ml-2 ">
        <h2 className="font-weight-bold">Conheça nossos livros</h2>
        <h3>
          Que te farão refletir, pensar, e com certeza o ajudarão a chegar mais longe, e a gerar resultados cada vez
          melhores dentro da sala de aula
        </h3>
        <Row className="d-flex justify-content-center p-4 ">
          <Link to="/livros">
            <Button className="btn-round w-100" style={{ width: "50%" }}>
              Mais detalhes
              <i class="fa fa-arrow-right"></i>
            </Button>
          </Link>
        </Row>
      </Col>
    </Row>
  );
}
