import React from "react";
import { Button, Col, Row } from "reactstrap";

export default function BookSection({ description, title, price, imgUrl, buyUrl, toggle, disabled }) {
  return (
    <div class="mx-5 mt-5 p-4 d-flex flex-column justify-content-center" style={{width: "30em"}}>
        <img class="d-block mx-auto" src={imgUrl} style={{ width: "35vh", height: "50vh"}} />
        <h2 class="m-0 mt-4" style={{ fontWeight: "bolder", textTransform: "uppercase"}}>{title}</h2>
        <p class="mb-1 mt-3 text-justify">
          {description.split(".").slice(0, 3).join(".") + ". "}
          <a href="#!"class="link-primary" onClick={() => toggle(title, description)}>Ler mais</a>
        </p>
        <div class="mt-auto d-block">
          <h2 class="font-weight-bold m-0"> {price} </h2>
          <h5 class="m-0 mb-3">+ FRETE GRÁTIS</h5>
          <a href={buyUrl} target="_blank">
            <Button disabled={disabled} color="danger" className="btn-round w-100">
              <i class="fa fa-shopping-basket"></i>
              COMPRAR
            </Button>
          </a>
        </div>
    </div>
  );
}
