

import MainFooter from 'components/Footers/MainFooter'
import MainNavbar from 'components/Navbars/MainNavbar'
import React from 'react'
import BooksSection from './components/BooksSection'
import ContactParallax from './components/ContactParallax'
import CourseParallax from './components/CourseParallax'
import Despositions from './components/Depositions'
import LectureCTA from './components/LectureCTA'
import HomeParallax from './components/MainParallax'
import Newsletter from './components/Newsletter'
import Services from './components/Services'
import TedXSection from './components/TedXSection'


export default function Home() {

    return (
        <div style={{overflowX: "hidden"}} >
            <MainNavbar />
            <HomeParallax />
            <BooksSection/>
            <TedXSection/>
            <Despositions/>
            <LectureCTA/>
            <CourseParallax/>
            <Newsletter/>
            <ContactParallax/>
            {/* <Services /> */}
            <MainFooter/>
        </div>
    )
}

