

import React from 'react';
import { useHistory } from 'react-router';
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Container, Row } from 'reactstrap';

export default function Thanks(props){
    const history = useHistory();
    return(
        <div style={{ height: "100vh", backgroundImage: `url(${require("../../assets/img/login-thumb.jpg")})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} >
            <div style={{ position: "absolute", backgroundColor: "#000000a6", width: "100vw", height: "100vh" }} />
            <Container className="h-100 ">
                <Row className="h-100 align-items-center">
                    <Col>
                        <Card className="text-center" >
                            <CardTitle>
                                <h2 className="m-0">Curso adquirido: </h2>
                               <h2 className="m-0 font-weight-bold"> Gestão da Sala de Aula </h2>
                            </CardTitle>
                            <CardBody style={{fontSize: 18}} className="pr-5 pl-5 mr-5 ml-5" >
                                Parabéns! Você acaba de adquirir o curso Gestão da Sala de Aula! Para acessá-lo, realize o login com o email informado durante a compra e para a <b>senha</b> utilize seu <b>CPF</b>
                            </CardBody>
                            <CardFooter>
                                <Button color="danger" className="btn-round" onClick={()=> history.push("/login")} >Realizar login</Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}