import MainFooter from 'components/Footers/MainFooter';
import MainNavbar from 'components/Navbars/MainNavbar';
import React from 'react';
import LectureSection from './components/LectureSection';
import LecturesMainParallax from './components/LecturesMainParallax';
import LecturesTitle from './components/LecturesTitle';

export default function Lectures(){

    return (
        <div style={{overflowX: 'hidden'}} >
            <MainNavbar/>
            <LecturesMainParallax/>
            <LecturesTitle/>

            <LectureSection 
                title="Ensinar não precisa ser chato!" 
                description="Este livro surge após minha fala no
                TEDx Rio do Sul, onde contei minha
                transformação e por isso decidi compartilhar e
                tentar motivar professores a se reinventarem e
                buscarem dias felizes. Esta reflexão visa buscar
                mudar a realidade de muitos educadores assim
                como eu mudei a minha."
                img={require("../../assets/img/professor-marcelo/IMG_20190616_155430_433.jpg")}
                link={"mailto:professormarcelo.com@gmail.com?subject=Palestra Ensinar não precisa ser chato!"}
            />
            <LectureSection 
                title="Acredite no Poder da Educação" 
                description="Com o passar do tempo, muitos educadores
                entram na zona de conforto. Por passar algumas
                situações na escola e fora dela, habituam-se a
                externar a culpa pelo fracasso de seus alunos e por
                suas aulas serem desmotivantes.
                Essa palestra é essencial para você sacudir a sua
                equipe de professores. Onde o Professor Marcelo
                constantemente busca exemplos vividos em seus
                projetos no continente africano conectando sobre
                alguns poderes. O da escolha, da alteridade, da
                reflexão, do planejamento e da missão de ser um
                educador."
                img={require("../../assets/img/professor-marcelo/IMG_20191026_130907.jpg")}
                link={"mailto:professormarcelo.com@gmail.com?subject=Palestra acredite no poder da educação"}
            />
            <LectureSection 
                title="Gestão da Sala de Aula" 
                description="A gestão da sala de aula se torna cada vez mais importante
                para o professor da atualidade. Muitos são os professores
                que enfrentam problemas com a indisciplina em suas aulas,
                além de possuírem muitos alunos com dificuldades de
                aprendizagem. Em muitos destes casos, uma boa gestão
                pode amenizar e até solucionar os problemas. Entender os
                motivos que possam levar os alunos a cometerem atos de
                indisciplina, e como funciona a aprendizagem, são requisitos
                básicos necessários para os educadores exercerem suas
                funções com eficiência."
                img={require("../../assets/img/professor-marcelo/bissau11.png")}
                link={"mailto:professormarcelo.com@gmail.com?subject=Palestra Gestão da Sala de Aula"}
            />
            <LectureSection 
                title="Crenças Pedagógicas" 
                description="Baseado em seu último livro, publicado em
                dezembro de 2021. Onde o professor Marcelo, busca
                nas teorias das crenças pedagógicas, promover
                reflexões sobre o professor que você se tornou.
                Essa palestra é altamente reflexiva e promove um
                autoconhecimento das suas fraquezas e
                potencialidades. O professor Marcelo ainda aborda
                sobre a importância de se usar a emoção no
                processo de ensino e da importãncia do erro no
                processo de aprendizagem."
                img={require("../../assets/img/professor-marcelo/IMG_20190506_183608.jpg")}
                link={"mailto:professormarcelo.com@gmail.com?subject=Palestra crenças pedagógicas"}
            />
            <LectureSection 
                title="Metodologias Ativas na Educação" 
                description="A tecnologia evoluiu de forma significativa nos
                últimos tempos. Porém, cabe ressaltar que a sala de
                aula ainda está com resquícios dos séculos passados.
                A forma da organização das carteiras, a forma de
                ensinar e de avaliar nossos alunos, fazendo com que
                eles sejam meros espectadores, não preparando
                nossos estudantes para o século XXI. Nesta palestra além de
                falar da importância das metodologias ativas para o
                mundo atual, apontamos inúmeros modelos e
                estratégias de tornar o aluno o protagonista da sala
                de aula, tornando a aula mais atrativa, dinâmica e
                eficiente."
                img={require("../../assets/img/professor-marcelo/IMG_20190616_155430_433.jpg")}
                link={"mailto:professormarcelo.com@gmail.com?subject=Palestra metodologias ativas na educação"}
            />
            <LectureSection 
                title="Motivação para ensinar e aprender" 
                description="É comum os professores não saberem como motivas
                seus alunos. Assim, baseado
                na teoria da motivação para ensinsar, o professor
                Marcelo explica aos professores como funciona a
                motivação para aprender e como podemos com
                estratégias simples, mudar a postura dos alunos e
                consequentemente a qualidade de nossas aulas."
                img={require("../../assets/img/professor-marcelo/IMG_20191026_130907.jpg")}
                link={"mailto:professormarcelo.com@gmail.com?subject=Palestra motivação para ensinar e aprender"}
            />
            {/* <LectureSection 
                title="Como Estudar com Qualidade" 
                description="Estudar tem seus segredos. Os educandos devem saber como estudar. para isto precisam saber como funciona o processo de aprendizagem. Nesta Palestra o professor  Marcelo, de uma forma bem descontraída passa aos alunos segredos importantes para que os alunos consigam aprender mais em menos tempo."
                img={require("../../assets/img/professor-marcelo/IMG_20190506_183608.jpg")}
                link={"mailto:professormarcelo.com@gmail.com?subject=Palestra Como estudar com qualidade"}
            /> */}
            <MainFooter/>
        </div>
    )
}