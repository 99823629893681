import React from 'react';
import { useHistory } from 'react-router';
import { Button, Col, Row } from 'reactstrap';

export default function CourseParallax() {
    const history = useHistory()
    return (
        <section className="d-flex justify-content-center align-items-center" style={{
            width: "100vw",
            height: "95vh",
            position: "relative",
            overflow: "hidden",
        }}>
            <div
                style={{
                    backgroundImage: `url(${require("assets/img/professor-marcelo/IMG-20191024-WA0068.jpg")})`,
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: "-2"
                }}
            />
            <div
                style={{
                    backgroundColor: "#000",
                    opacity: .8,
                    backgroundAttachment: "fixed",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100%",

                }}
            />

            <Row className="text-white" style={{ width: "100%", height: "100%" }} >
                <Col className="d-flex flex-column align-items-center justify-content-center text-white">
                    <h3 >Curso</h3>
                    <h1 className="font-weight-bold m-0" style={{textAlign: "center"}}>
                        Gestão da Sala da Aula
                    </h1>
                    <Row  className="pr-4 pl-4 mr-4 ml-4">
                        <Col className="pr-4 pl-4" >
                            <h4 style={{textAlign:"center"}}>
                                Teoria conectada a práticas eficientes e a solução dos problemas
                            </h4>

                        </Col>
                        <Col  className="pr-4 pl-4">
                            <h4 style={{textAlign:"center"}}>
                                Mude a realidade da sala de aula e do ambiente de aprendizado
                            </h4>
                        </Col>
                    </Row>

                    <Button onClick={()=> history.push("/cursos-professor")} style={{opacity: 1}} className="mt-4 btn-round" color="danger"  >
                        Saiba mais
                        <i class="fa fa-arrow-right"/>
                    </Button>
                </Col>
            </Row>

        </section>
    );
}