import MainFooter from 'components/Footers/MainFooter';
import MainNavbar from 'components/Navbars/MainNavbar';
import React from 'react';

import { Col, Row, } from 'reactstrap';
import MainAboutSection from './MainAboutSection';

export default function About() {
    return (
        <div style={{ overflowX: "hidden" }} >
            <MainNavbar />

            <MainAboutSection />

            <Row className="text-dark p-4 ml-4 mr-4 w-100 mb-4" >
                <Col>
                    {/* <Row>
                        <Col>
                            <h4 style={{ lineHeight: 1.4 }}>
                                Licenciado em Geografia e Pedagogia, com especialização em Gestão Escolar, Mestrado em Planejamento Urbano e Desenvolvimento Socioambiental e Doutorando em Educação Científica e Tecnológica. Possui experiência de 20 anos de sala de aula, tendo atuado como gestor e professor. Atualmente além de palestrante, escritor e professor, da assessoria a escolas e gestores, além de ser pesquisador e extensionista.
                        </h4>
                        </Col>
                    </Row> */}

                    <Row>
                        <Col sm="12">
                            <h4 style={{ lineHeight: 1.4 }}>
                                Palestrou em todas as regiões do Brasil e em três países, na atualidade realiza projetos na formação de professores no Brasil e na Guiné-Bissau.
                            </h4>
                        </Col>
                        <Col>
                            <h4 className="mb-2 ml-3"> <i class="fa fa-book"></i> <strong> LIVROS PUBLICADOS: </strong></h4>
                            <ul>
                                <li>
                                    <h5>

                                        Memogeo (2013)
                                    </h5>
                                </li>
                                <li>
                                    <h5>

                                        Gestão da Sala de Aula (2018)
                            </h5>
                                </li>
                                <li>
                                    <h5>

                                        Acredite no Poder da Educação (2019)
                            </h5>

                                </li>
                                <li>
                                    <h5>

                                        Ensinar Não Precisa Ser Chato ( 2020)
                                </h5>
                                </li>
                                <li>
                                    <h5>

                                        Provocações Pedagógicas no Chão da Sala de Aula (2020)
                                </h5>
                                </li>
                                <li>
                                    <h5>

                                        O Professor que me tornei (2021)
                                </h5>
                                </li>
                            </ul>
                        </Col>
                    </Row>

                </Col>

            </Row>

            <MainFooter />
        </div>
    )
}