

import React from 'react';
import {Col, Row} from 'reactstrap'

export default function CoursesMainParallax(){

    return (
        <section className="d-flex justify-content-center align-items-center" style={{
            width: "100vw",
            height: "100vh",
            position: "relative",
            overflow: "hidden",
          }}>
            <div
              style={{
                backgroundImage: `url(${require("assets/img/professor-marcelo/courses.jpeg")})`,
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: "-2"
              }}
            />
            <div
              style={{
                backgroundColor: "#000",
                opacity: .8,
                backgroundAttachment: "fixed",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100%",
      
              }}
            />
      
            <Row className="text-white" style={{ width: "100%", height: "100%" }} >
              <Col xs="9" md="12" className="d-flex flex-column justify-content-center h-100" style={{ width: "100vw" }}>
                <Row className="d-flex  w-100 ml-4">
      
                  <h2>CURSOS PARA APRENDER E ENSINAR</h2>
      
                </Row>
              </Col>
            </Row>
      
          </section>
    )
}