
import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap'



const depositions = [
    {
        text: '"Sensacional sua palestra. meus sinceros parabéns! Foi muito bom dividir este palco com você professor."',
        author: "- Diogo Portugal - Humorista, São Paulo-SP"
    },
    {
        text: '"Foi uma honra estar presente na sua palestra professor Marcelo. Com certeza um grande momento pra quem ama a educação."',
        author: '- Profº Wesley Souza - Brasília - DF'
    },
    {
        text: '"Motivacional, reflexiva e prática. Que todos os professores pudessem ter uma palestra como essa em sala de aula. Necessitamos de teoria + prática!"',
        author: '- Profº Fernando Souza - Palhoça - SC'
    },
]


export default function Despositions(props) {

    return (
        <Col className="pt-2 pb-4 mb-4" style={{paddingRight: "14vw", paddingLeft: "14vw"}} >
            <Row>
                <Col className="pr-4 pl-4" style={{ textAlign: "center" }}>
                    <h2  >
                        {depositions[0].text}
                    </h2>
                    <h6 className="pt-3">
                        {depositions[0].author}
                    </h6>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center" >
                <h1 className="font-weight-bold" style={{textAlign: 'center'}}>
                    "PALESTRA INSPIRADORA"
                    </h1>
            </Row>

            <Row className="pl-4 pr-4" >
                <Col className="pt-2 pr-4 pl-4">
                    <div>
                        <h4>
                            {depositions[1].text}
                        </h4>
                        <h6 className="pt-3">
                            {depositions[1].author}
                        </h6>
                    </div>
                </Col>
                <Col className="pt-2 pr-4 pl-4">
                    <div>
                        <h4>
                            {depositions[2].text}
                        </h4>
                        <h6 className="pt-3">
                            {depositions[2].author}
                        </h6>
                    </div>
                </Col>
            </Row>
        </Col>
    )
}
