import React from 'react';
import { Button, Col, Row } from 'reactstrap';

export default function LectureSection(props) {

    let borderRadius = 12

    return (
        <Row className="pr-5 pl-5 pt-2 pb-3 mb-5 justify-content-center align-items-center" >
            <Row className="text-white w-100 h-75" style={{ borderRadius, position: "relative", }}>
                <div
                    style={{
                        backgroundImage: `url(${props.img})`,
                        borderRadius,
                        backgroundAttachment: "fixed",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: "-2"
                    }}
                />
                <div
                    style={{
                        backgroundColor: "#000",
                        borderRadius,
                        opacity: .8,
                        backgroundAttachment: "fixed",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",

                    }}
                />

                <Col className="mb-5 d-flex flex-column justify-content-center align-items-center" >
                    <h2 className="font-weight-bold text-center" style={{ textTransform: "uppercase" }} > {props.title} </h2>
                    <h4 className="mt-5 mb-5 text-center pr-3 pl-3" style={{lineHeight: 1.4, letterSpacing: 1.2}}>
                        {props.description}
                    </h4>
                    <a className="w-75 d-flex flex-column justify-content-center align-items-center" href={props.link} >
                        <Button className="w-75 btn-round" color="danger" >CONTRATE AGORA!</Button>
                    </a>
                </Col>
            </Row>
        </Row>
    )
}