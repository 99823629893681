import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { getUserCourses } from 'repositories/courses.js'
import CourseCard from './components/CourseCard.js'
import { ModulesContext } from '../../context/module_context'

export default function CourseSelection(props) {
    const [cursos, setCursos] = useState([])

    async function getData() {
        const response = await getUserCourses();
        if (!response.success) return "ASAS"
        setCursos(response.data)

    }

    useEffect(() => { getData() }, [])

    return (
        <div style={{
            height: "100vh",
            backgroundImage: `url(${require("../../assets/img/course-selection.jpg")})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "fixed",
            backgroundAttachment: "fixed"
        }} >
            <div style={{ position: "absolute", backgroundColor: "#000000cc", width: "100%", height: "100%" }} />
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs="12" sm="6" className="my-auto">
                        <h2 className="font-weight-bold text-white text-uppercase text-center" >Selecione um dos seus cursos </h2>
                    </Col>
                    <Col className="p-5 my-auto" >
                        <div
                            className="p-4 text-center"
                            style={{
                                backgroundColor: "#fff",
                                borderRadius: 16
                            }}>
                            <h4 className="m-0 mb-4  font-weight-bold">Escolha um dos cursos abaixo</h4>

                            {
                                cursos.map(curso => <CourseCard title={curso.nome} courseId={curso.id} />)
                            }

                            <Button color="danger" className="btn-round mt-4" onClick={() => window.location.href = "/cursos-professor"} >
                                <i class="fa fa-plus"></i>
                                Adquirir mais cursos
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}