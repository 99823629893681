// import { BACKEND_URL } from "config/constants";
// import { getToken } from "services/auth";
import { loadCSVasJSON } from 'services/csv_loader';


// export async function getModuleContents(moduleId) {
export async function getModuleContents(nomeCurso) {
    try {
        // const response = await fetch(BACKEND_URL + `/modulos?cursos.nome=${moduleId}`, {
        //     method: "GET",
        //     headers: {
        //         Authorization: `Bearer ${getToken()}`
        //     }
        // }) ;
        // const json = await response.json();
        // return {
        //     success: true,
        //     data: json
        // }

        var responseModulosCursos = await loadCSVasJSON("Banco de Dados - Cursos Módulos.csv")
        responseModulosCursos = responseModulosCursos.filter(el => el.nomeCurso == nomeCurso)
        var responseModulos = await loadCSVasJSON("Banco de Dados - Módulos.csv")
        responseModulos = responseModulos.filter(modulo => {
            var moduloCurso = responseModulosCursos.find(mc => mc.nomeModulo == modulo.nome)
            return moduloCurso != undefined
        })
        const responseAulas = await loadCSVasJSON("Banco de Dados - Aulas.csv")
        const responseCursos = await loadCSVasJSON("Banco de Dados - Cursos.csv")
        responseModulos.forEach(modulo => {
            modulo["aulas"] = responseAulas.filter(aula => aula.nomeModulo == modulo.nome)
            modulo["cursos"] = responseCursos.filter(curso => responseModulosCursos.find(mc => mc.nomeCurso == curso.nome && mc.nomeModulo == modulo.nome))
        });
        return {
            success: true,
            data: responseModulos
        }
    } catch (error) {
        return {
            success: false,
            error
        }
    }
}