

import React from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Card } from 'reactstrap';

export default function ContactParallax(props) {

    return (
        <section className="d-flex justify-content-center align-items-center mt-4" style={{
            width: "100vw",
            height: "60vh",
            position: "relative",
            overflow: "hidden",
        }}>

            <div
                style={{
                    backgroundImage: `url(${require("assets/img/professor-marcelo/IMG_20190508_095456.jpg")})`,
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: " 100%",
                }}
            />
            <div
                style={{
                    backgroundColor: "black",
                    backgroundAttachment: "fixed",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: " 100%",
                    opacity: .8
                }}
            />

            <Row className="text-white" style={{ width: "100%", height: "100%" }} >
                <Col className="d-flex flex-column align-items-center" >
                    <h1>CONTATO</h1>
                    <hr />
                    <Row className="d-flex justify-content-center w-100">
                        <a href="tel:+554799813532" >
                            <Button color="danger" style={{ width: 60, height: 60 }} className="btn-round d-flex justify-content-center align-items-center">
                                <i class="fa fa-phone" style={{ fontSize: 22 }}></i>
                            </Button>
                        </a>
                        <div style={{ width: "5%" }} />
                        <a href="https://api.whatsapp.com/send?phone=554799813532&text=Ol%C3%A1%20Professor%20Marcelo%2C%20gostaria%20de%20falar%20contigo!%20" target="_blank">
                            <Button color="danger" style={{ width: 60, height: 60 }} className="btn-round d-flex justify-content-center align-items-center">
                                <i class="fa fa-whatsapp" style={{ fontSize: 22 }}></i>
                            </Button>
                        </a>
                        <div style={{ width: "5%" }} />
                        {/* <Button color="danger" style={{ width: 60, height: 60 }} className="btn-round d-flex justify-content-center align-items-center">
                            <i class="fa fa-telegram" style={{ fontSize: 24 }}></i>
                        </Button>
                        <div style={{ width: "5%" }} /> */}
                        <a href="https://www.instagram.com/_professormarcelo/?hl=pt-br" target="_blank" >
                            <Button color="danger" style={{ width: 60, height: 60 }} className="btn-round d-flex justify-content-center align-items-center">
                                <i class="fa fa-instagram" style={{ fontSize: 22 }}></i>
                            </Button>
                        </a>
                    </Row>
                    <h3 className="font-weight-bold">OU</h3>
                    <Row className="w-100 d-flex justify-content-center mt-4">
                        <Col sm="12" md="5" className="text-center">
                            {/* <div className="bg-white p-4 text-dark font-weight-bold" style={{borderRadius: 8}} >
                                <form>
                                    <FormGroup>
                                        <Label for="contact_name" >Nome:</Label>
                                        <Input id="contact_name" name="contact_name" type="text" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="contact_email" >Email:</Label>
                                        <Input id="contact_email" name="contact_email" type="email" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="contact_phone" >Celular:</Label>
                                        <Input id="contact_phone" name="contact_phone" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="contact_description" >Deixe sua mensagem:</Label>
                                        <Input id="contact_description" name="contact_description"  type="textarea"/>
                                    </FormGroup>
                                    <FormGroup className="d-flex justify-content-center m-0"> */}
                            <Button color="danger" className="btn-round mt-2 mb-5" >
                                <a href="mailto:marceloeducador@gmail.com?subject=Contato Professor Marcelo&body=" style={{ textDecoration: "none", color: "#fff", fontWeight: "bold" }}>
                                    Enviar email
                                            <i class="fa fa-envelope"></i>
                                </a>
                            </Button>
                            {/* </FormGroup>
                                </form>
                            </div> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>

    )
}