

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ContentCard from './ContentsCard';

export default function ModuleContents({ actualModule }) {

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h2 className="text-uppercase font-weight-bold" >{actualModule.nome}</h2>
                    <hr />
                    <Row>                        
                    {
                        actualModule.aulas.map(aula => (
                            <Col xs="12" lg="3" >
                                <ContentCard aula={aula} />
                            </Col>
                        ))
                    }
                    </Row>

                   
                </Col>
            </Row>
        </Container>
    )
}





