import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Spinner } from 'reactstrap';
import { handleLogin } from 'repositories/login';

export default function Login(props) {

    const emailRef = useRef();
    const passwordRef = useRef();
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    async function handleSubmit() {
        setError(null);
        if (!emailRef.current.value) return setError("Informe um email")
        if (!passwordRef.current.value) return setError("Informe uma senha")
        setLoading(true);
        const loginResponse = await handleLogin({ email: emailRef.current.value, password: passwordRef.current.value })
        setLoading(false);
        if (!loginResponse.success) {
            let errorText = loginResponse.error.message == "Credenciais invalidadas" ? "Email ou senha incorretos" : "Ocorreu um erro ao realizar o login"
            setError(errorText)
            return;
        }
        window.location.href = "/selecionar-curso"
    }

    return (
        <div style={{ height: "100vh", backgroundImage: `url(${require("../../assets/img/login-thumb.jpg")})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} >
            <div style={{ position: "absolute", backgroundColor: "#000000a6", width: "100vw", height: "100vh" }} />
            <Container className="h-100" >
                <Row className="h-100 align-items-center justify-content-center" >
                    <Col md="8" xs="12" >
                        <h2 className="m-0 mb-3 text-uppercase font-weight-bold text-white text-center">PROFESSOR MARCElo</h2>
                        <h2 className="m-0 mb-4 text-uppercase  text-white text-center">Entrar</h2>
                        <Card className="p-3" >
                            <CardBody>
                                <Row>
                                    <Col >
                                        {
                                            error && <Alert color="danger" > <i class="fa fa-exclamation-circle"></i> {error} </Alert>
                                        }
                                        <Row>
                                            <Col className="text-center">
                                                {/* <h3 className="m-0 text-uppercase font-weight-bold text-dark" >Entrar</h3> */}
                                                <h4 className="m-0">Realize seu login para entrar na Plataforma </h4>
                                            </Col>
                                        </Row>
                                        <Form>
                                            <FormGroup>
                                                <Label for="login_email">Email</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i class="fa fa-envelope"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input innerRef={emailRef} type="email" id="login_email" name="email" />
                                                </InputGroup>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="login_password">Senha</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i class="fa fa-lock"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input innerRef={passwordRef} type="password" id="login_password" name="password" />
                                                </InputGroup>
                                            </FormGroup>
                                            <Row>
                                                <Col className="d-flex flex-column justify-content-center align-items-center mt-1" >
                                                    {/* <Link className="mb-3 text-dark "> Esqueceu sua <b className="font-weight-bold text-danger">senha</b> ? </Link> */}
                                                    <Button color="danger" className="w-75" onClick={handleSubmit} disabled={loading} >
                                                        {
                                                            !loading ?
                                                                "Login"
                                                                :
                                                                <>
                                                                    <Spinner color="white" size="sm" className="mr-3" />
                                                                    Carregando...
                                                                </>
                                                        }
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}