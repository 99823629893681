import React from 'react';
import { Col, Row } from 'reactstrap';

export default function LecturesTitle() {

    return (
        <Row className="mb-4" style={{ width: "100vw" }} >
            <Col className="w-25 p-4 pr-5 pl-5 text-center">
                <h1 className="font-weight-bold" >"Reflexões Pedagógicas"</h1>
                <h3 className="text-center" >
                   Marcelo da Silva fala sobre o Poder da Educação, da importância de um olhar diferenciado do educador aos educandos, insiste em frisar que a Educação é heterogênea demais para homogeneizar. Acredita que estamos perpetuando modelos educacionais pouco efetivos no mundo atual. Já palestrou em todas as regiões do Brasil e no continente Africano. Em Semanas Pedagógicas, Universidades, Faculdades, Escolas e Prefeituras.
                </h3>


            </Col>
        </Row>
    )
}