import MainFooter from "components/Footers/MainFooter";
import MainNavbar from "components/Navbars/MainNavbar";

import React from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import CoursesMainParallax from "./components/CoursesMainParallax";

export default function Courses() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <MainNavbar />
      <CoursesMainParallax />

      <Row className={`mt4 ${window.innerWidth > 578 ? "p-5" : ""}`}>
        <Col className="p-0">
          <h2 className="font-weight-bold text-uppercase mb-3 text-center">
            Gestão da Sala de Aula
          </h2>
          <Row>
            <Col xs="12" sm="6">
              <div
                style={{
                  height: "68vh",
                  width: window.innerWidth > 578 ? "46vw" : "100vw",
                }}
              >
                <iframe
                  style={{ border: 0 }}
                  height="100%"
                  width="100%"
                  src="https://player.vimeo.com/video/556825842?title=0&muted=1&autoplay=1&loop=1&app_id=122963"
                />
              </div>
            </Col>
            <Col className="p-2 pr-5 pl-5">
              <div className="mb-4">
                <h3 className="font-weight-bold">
                  O curso é dividido em quatro módulos:{" "}
                </h3>
                <h3 className="mt-1">- Gestão da Disciplina.</h3>
                <h3 className="mt-1">- Gestão do Tempo.</h3>
                <h3 className="mt-1">- Gestão da Aprendizagem.</h3>
                <h3 className="mt-1">- Gestão das Relações Interpessoais.</h3>
              </div>
              {/* <Card>
                <CardBody>
                  <Row>
                    <Col xs="12" sm="7">
                      <h3 className="font-weight-bold text-uppercase m-0">
                        Acesso Gratuito
                      </h3>
                      <h3 className="m-0"> R$ 174,00</h3> 
                    </Col>
                    <Col className="mt-3 d-flex flex-column justify-content-center align-items-center"> */}
                
              {/* Botão garanta acesso antigo */}
              {/* <a href="mailto:contato@professormarcelo.com?subject=Cadastro no Professor Marcelo ">
                <Button className="w-100 btn-round" color="danger">
                  <h3 className="m-0 uppercase font-weight-bold">
                    GARANTA ACESSO AGORA
                  </h3>
                </Button>
              </a> */}

              <Button className="w-100 btn-round" color="danger" disabled>
                <h3 className="m-0 uppercase font-weight-bold">
                  EM BREVE
                </h3>
              </Button>
              {/* </Col>
                  </Row>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
        </Col>
      </Row>

      <MainFooter />
    </div>
  );
}
