import MainFooter from "components/Footers/MainFooter";
import MainNavbar from "components/Navbars/MainNavbar";
import React from "react";
import {ModalFooter, Modal, ModalHeader, ModalBody, Button} from "reactstrap";
import BooksMainParallax from "./components/BooksMainParallax";
import BookSection from "./components/BookSection";

export default function Books(props) {
  const professorQueMeTornei = {
    title: "O Professor que Me Tornei",
    price: "R$ 32,00",
    description:
      "Este livro não fala sobre mim, mas sobre você. O professor que você se tornou, no que você acredita e prática, ou seja, nas suas crenças pedagógicas e na sua motivação para ensinar. O livro é dividido em cinco capítulos, onde primeiramente falo sobre as crenças pedagógicas, o que são e como elas são constituídas. Posteriormente falo da formação de professores, como ela contribui para a composição das suas crenças pedagógicas e consequentemente para a sua prática docente. Sigo procurando promover reflexões, falando sobre a prática profissional, o seu dia-a-dia, e como as suas práticas influenciam para formar as suas crenças pedagógicas. Há um capítulo sobre a motivação para ensinar, algo necessário, onde procuro despertar no leito a consciência da sua realidade, Por fim falo da importância da emoção no processo de ensino. Desde nossa capa temos interações em vídeo, que você conseguirá acessar pelos QR-CODES, as reflexões vão do início ao final do livro. Afinal, meu grande objetivo é fazer você pensar e sim ser feliz com a sua profissão.",
    imgUrl: require("../../assets/img/professor-marcelo/o-professor-que-me-tornei.jpeg"),
    buyUrl: "https://pag.ae/7XQR7Ro61",
  };

  const gestao = {
    title: "Gestão da Sala de Aula",
    price: "R$ 32,00",
    description:
      "A gestão da sala de aula se torna cada vez mais importante para o professor da atualidade. Muitos são os professores que enfrentam problemas com a indisciplina em suas aulas, além de possuírem muitos alunos com dificuldades de aprendizagem. Em muitos destes casos, uma boa gestão pode amenizar e até solucionar os problemas. Entender os motivos que possam levar os alunos a cometerem atos de indisciplina, e como funciona a aprendizagem, são requisitos básicos necessários para os educadores exercerem suas funções com eficiência. O tempo é outro elemento que os professores devem ter o controle. Como começar uma aula? Como conectar a aula atual com a aula anterior? Como agir numa turma pouco participativa e conduzir a aula com grande aproveitamento?Por fim, abordaremos dois assuntos muito importantes. Primeiro sobre as nossas relações interpessoais com alunos, professores, gestores e pais. E para finalizar, apontamos estratégias, com dicas para planejar a sua carreira com clareza e eficiência em busca da conquista de seus objetivos",
    imgUrl: require("../../assets/img/professor-marcelo/20e65eef83d450b295000616a1dc04d5.jpg"),
    buyUrl: "https://pag.ae/7Wp2Sz1r1",
  };

  const poderEducacao = {
    title: "Acredite no Poder da Educação",
    price: "R$ 34,00",
    description:
      "Parafraseando Foucault, o poder não existe enquanto coisa, ele é um funcionamento, um mecanismo; “poder mais se exerce do que se possui”. O Poder da Educação deve ser exercido, por você, por mim, por todos aqueles que estão envolvidos no âmbito educacional. Acreditar se faz necessário, pois assim seremos capazes de modificar vidas, enfrentar adversidades e despertar sonhos. Assim como alguém te fez acreditar, você usou o poder da escolha, planejou, realizou e mudou vidas. E assim devemos seguir, com alteridade, esperança e enfrentamento. As adversidades serão grandes, mas aquele sonho que foi por você sonhado, merece ser realizado. Amigo leitor proponho uma conversa, sobre educação, sonhos e poderes. Vem comigo: ACREDITE NO PODER DA EDUCAÇÃO.",
    imgUrl: require("../../assets/img/professor-marcelo/livropoder.jpeg"),
    buyUrl: "https://pag.ae/7Wp2TiTMb",
  };

  const ensinarChato = {
    title: "Ensinar não precisa ser Chato",
    price: "R$ 34,00",
    description:
      "Quando cheguei no meu limite, quando pensava em mudar de profissão, eu me reinventei. Parte de mim pedia, Chega! você já tentou de tudo, outra murmurava, lute, acredite você pode ser feliz na sala de aula. Eu acreditei, criei estratégias eficazes que não apenas me deixaram muito feliz e realizado como professor, mas me deram uma visão diferente do ensino, e hoje me sinto completamente realizado e feliz com minha profissão. Este livro surge após minha fala no TEDx Rio do Sul, onde contei minha transformação e por isso decidi compartilhar e tentar motivar professores a se reinventarem e buscarem dias felizes. convido a ler esse livro e quem sabe mudar a sua realidade assim como eu mudei a minha.",
    imgUrl: require("../../assets/img/professor-marcelo/ensinar.png"),
    buyUrl: "https://pag.ae/7Wp2TyPjs",
  };

  const provocacoesPedagogicas = {
    title: "Provocações Pedagógicas no Chão da Sala de Aula",
    price: "R$ 34,00",
    description:
      "O desafio diário do professor em estar envolvido no chão da sala de aula, contextualizando suas vivências e socializando as provocações cotidianas, na busca de alternativas que superem essas, é a reflexão com proposições dos autores que “Provocações Pedagógicas no Chão da Sala de Aula” traz. Repensar o dia-a-dia no espaço que deve ser prazeroso para professor e aluno, nos fez sacudir as diferentes realidades que se tornam comum na maioria das escolas brasileiras e muitas vezes, queixas constantes nas salas de professores e nos Conselhos de Classe. Propor novas alternativas para esses desafios comuns é o que os autores com suas experiências e vivencias, pretendem com esse livro.",
    imgUrl: require("../../assets/img/professor-marcelo/WhatsApp Image 2020-08-24 at 08.34.25.jpeg"),
    buyUrl: "https://pag.ae/7Wp2TRQVv",
  };

  const [modalDesc, setModalDesc] = React.useState("Descrição")
  const [modalTitle, setModalTitle] = React.useState("Título")
  const [modal, setModal] = React.useState(false);
  const toggle = (title = null, desc = null) => {
    setModalTitle((title) ? title : modalTitle)
    setModalDesc((desc) ? desc : modalDesc)
    setModal(!modal)
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <MainNavbar />
      <BooksMainParallax />
      <div class="d-flex flex-row justify-content-around flex-wrap">
        <BookSection
          description={professorQueMeTornei.description}
          price={professorQueMeTornei.price}
          title={professorQueMeTornei.title}
          imgUrl={professorQueMeTornei.imgUrl}
          buyUrl={professorQueMeTornei.buyUrl}
          toggle={toggle}
        />
        <BookSection
          description={gestao.description}
          price={gestao.price}
          title={gestao.title}
          imgUrl={gestao.imgUrl}
          buyUrl={gestao.buyUrl}
          toggle={toggle}
        />
        <BookSection
          description={poderEducacao.description}
          price={poderEducacao.price}
          title={poderEducacao.title}
          imgUrl={poderEducacao.imgUrl}
          buyUrl={poderEducacao.buyUrl}
          toggle={toggle}
        />
        <BookSection
          description={ensinarChato.description}
          price={ensinarChato.price}
          title={ensinarChato.title}
          imgUrl={ensinarChato.imgUrl}
          buyUrl={ensinarChato.buyUrl}
          toggle={toggle}
        />
        <BookSection
          description={provocacoesPedagogicas.description}
          price={provocacoesPedagogicas.price}
          title={provocacoesPedagogicas.title}
          imgUrl={provocacoesPedagogicas.imgUrl}
          buyUrl={provocacoesPedagogicas.buyUrl}
          toggle={toggle}
        />
      </div>
      <div style={{ height: "10vh" }} />
      <MainFooter />
      <Modal
        isOpen={modal}
        toggle={() => toggle()}
      >
        <ModalHeader>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          {modalDesc}
        </ModalBody>
        <ModalFooter className="border-0">
          <Button className="m-3" onClick={() => toggle()}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
