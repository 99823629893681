import { loadCSVasJSON } from 'services/csv_loader';


// export function getLocalSavedCourses() {
//     const { cursos } = JSON.parse(localStorage.getItem("user"))
//     return cursos
// }

export async function getUserCourses() {
    try {
        // const response = await fetch(BACKEND_URL + `/users/${userId}`, {
        //     method: "GET",
        //     headers: {
        //         Authorization: `Bearer ${getToken()}`
        //     }
        // })
        // const json = await response.json();

        const user = JSON.parse(localStorage.getItem("user"))
        var responseUserCursos = await loadCSVasJSON("Banco de Dados - Usuário Curso.csv")
        responseUserCursos = responseUserCursos.filter(el => el["emailUsuário"] == user.email)
        var responseCursos = await loadCSVasJSON("Banco de Dados - Cursos.csv")
        responseCursos = responseCursos.filter(c => {
            const userCurso = responseUserCursos.find(uc => uc.nomeCurso == c.nome)
            return userCurso != undefined
        })

        return {
            success: true,
            data: responseCursos
        }
    } catch (error) {
        return {
            success: false,
            error
        }
    }
}