import React from 'react';
import { Button, Col } from 'reactstrap';


export default function Newsletter() {
    return (
        <Col className="my-5 d-flex flex-column justify-content-center align-items-center" >
            {/* <div className="bg-dark mb-5" style={{width: "90vw", height: 1}} /> */}
            <h2 className="font-weight-bold text-center pr-4 pl-4" > Quer descobrir mais formas de transformar a sua sala de aula? </h2>
            <h3 className="text-center mb-4">Ouça nosso podcast!</h3>

            <Button color="danger" className="btn-round mt-2 mb-5" >

                <a href="https://olapodcasts.com/channels/falardeeducacao" style={{ textDecoration: "none", color: "#fff", fontWeight: "bold" }}>
                    OLA Podcasts
                    <i class="fa fa-paper-plane"></i>
                </a>
            </Button>

            {/* <h3 className="text-center mb-4">Clique no botão abaixo e envie "Acredito no poder da Educação"</h3>

            <Button color="danger" className="btn-round mt-2 mb-5" >

                <a href="mailto:marceloeducador@gmail.com?subject=Cadastro Grupo Seleto&body=Acredito no Poder da Educação!" style={{ textDecoration: "none", color: "#fff", fontWeight: "bold" }}>
                    Se Inscrever
                    <i class="fa fa-paper-plane"></i>
                </a>
            </Button> */}
            {/* <div className="bg-dark mt-5" style={{width: "90vw", height: 1}} /> */}
        </Col>
    )
}