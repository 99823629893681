

import React from 'react';
import { Col, Row } from 'reactstrap';

export default function TedXSection() {
    return (
        <section className="d-flex justify-content-center align-items-center" style={{
            width: "100vw",
            position: "relative",
            overflow: "hidden",
        }}>
            <div
                style={{
                    backgroundImage: `url(${require("assets/img/professor-marcelo/tedx_palestra.png")})`,
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: "-2"
                }}
            />
            <div
                style={{
                    backgroundColor: "#000",
                    opacity: .8,
                    backgroundAttachment: "fixed",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: " 100%",
                }}
            />
            <Col className="text-white my-5" >
                <h2 style={{ textAlign: 'center'}} className="font-weight-bold mb-4 mt-0" >
                    TEDx <br/> Palestras no Brasil e na África
                </h2>
                <center>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/g26JJA42t-A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </center>
                <Row className="pl-4 pr-4" >
                   
                    <Col>
                        <div>
                            <h4 style={{textAlign: "center"}}>
                                "Sua palestra despertou muita reflexão em nossos professores. Onde ficou claro, que devemos  sair da zona de conforto e buscar as soluções necessárias para atender os anseios de nossos educandos".
                        </h4>
                            <h6 style={{textAlign: "center"}} className="pt-3">
                                - Profª Cátia Geremias - Trombudo Central - SC
                            </h6>
                        </div>
                    </Col>
                </Row>
            </Col>
        </section>
    )
}