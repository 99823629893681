
import React from "react";

import { Row, Container } from "reactstrap";

function MainFooter() {
  return (
    <footer className="footer bg-dark text-white">
      <Container>
        <Row>
          <nav className="footer-nav text-white">
            <ul>
              {/* <li>
                <a
                  className="text-white"
                  href="https://www.creative-tim.com?ref=pkr-footer"
                  target="_blank"
                >
                  Tedx
                </a>
              </li>
              <li>
                <a
                  className="text-white"
                  href="http://blog.creative-tim.com/?ref=pkr-footer"
                  target="_blank"
                >
                  Blog
                </a>
              </li> */}
              
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright text-white">
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" /> by BEYONDERS
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default MainFooter;
