

import React, { useState } from 'react';
const DEFAULT_VALUES = {
    modules: [],
    actualModule: null
}

export const ModulesContext = React.createContext(DEFAULT_VALUES);

export default function ModulesProvider({children}){
    const [modulesContext, setModulesContext] = useState(DEFAULT_VALUES)

    return (
        <ModulesContext.Provider value={{modulesContext, setModulesContext}}>
            {
                children
            }
        </ModulesContext.Provider> 
    )
}

