
import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import { Card, CardBody, CardImg, CardImgOverlay } from "reactstrap";

function getImage() {
    switch (Math.random() * 10) {
        case 1:
            return require( "../../../assets/img/img1.jpg");
        case 2:
            return require("../../../assets/img/img2.jpg");
        case 3:
            return require("../../../assets/img/img3.jpg");
        default:
            return require("../../../assets/img/img4.jpg");
    }
}

export default function CourseCard({ courseId, title }) {
    const history = useHistory()
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
      })
    return (
        <Card key={Date.now()+courseId} style={{cursor: "pointer", height: "180px "}} onClick={()=>history.push("/modulos/main", {id: courseId, nome: title})} >
            <CardImg style={{height: "100%", borderRadius: 16}} src={getImage()} />
            <CardImgOverlay style={{backgroundColor: "#000000a7", borderRadius: "16px"}}>
                <CardBody className="text-center" >
                    <h2 className="m-0 font-weight-bold text-center text-white text-uppercase" style={{fontSize: isTabletOrMobileDevice ? 20: 36}} >{title}</h2>
                </CardBody>
            </CardImgOverlay>
        </Card>
    )
}