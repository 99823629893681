

import { ModulesContext } from 'context/module_context';
import React, { useContext, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import { getModuleContents } from 'repositories/modules';
import ClassSection from './components/ClassSection';
import ModuleContents from './components/ModuleContents';
import VerticallNav from './components/VerticalNav';

export default function Contents(props) {
    const [loading, setLoading] = useState(true)
    const { modulesContext, setModulesContext } = useContext(ModulesContext);
    useEffect(() => { getData() }, [])

    async function getData() {
        try {
            const moduleName = props.location.state.nome
            const contents = await getModuleContents(moduleName);
            console.log(contents, moduleName)
            if (!contents.success) return window.location.href = "/selecionar-curso";
            setModulesContext({
                modules: contents.data,
                actualModule: contents.data[0]
            })
            setLoading(false)
        } catch (err) {
            console.log(err);
            // return window.location.href = "/selecionar-curso"
        }

    }

    if (loading) return "Carregando..."
    return <Render />
}

function Render(props) {
    const match = useRouteMatch()
    const { modulesContext, setModulesContext } = useContext(ModulesContext);

    function setActualModule(module) {
        setModulesContext({
            ...modulesContext,
            actualModule: module
        })
    }

    return (
        <Container fluid>
            <Row>
                <Col className="p-0 m-0" xs="12" md="3" lg="2" >
                    <VerticallNav setActualModule={setActualModule} modules={modulesContext.modules} />
                </Col>
                <Col>
                    <Switch>
                        <Route exact path={`${match.path}/main`} render={(props) => <ModuleContents {...props} actualModule={modulesContext.actualModule} />} />
                        <Route exact path={`${match.path}/aula`} render={(props) => <ClassSection {...props} />} />
                    </Switch>
                </Col>

            </Row>
        </Container>
    )

}