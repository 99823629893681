/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";
// pages
import Home from "Pages/Home";
import About from "Pages/About";
import Books from "Pages/Books";
import Lectures from "Pages/Lectures";
import Courses from "Pages/Courses";
import Login from "Pages/Login";
import CourseSelection from "Pages/CourseSelection";
// import NucleoIcons from "views/NucleoIcons.js";
// import LandingPage from "views/examples/LandingPage.js";
// import ProfilePage from "views/examples/ProfilePage.js";
// import RegisterPage from "views/examples/RegisterPage.js";
// others

import { isAuthenticated } from "./services/auth"
import CourseDashboard from "Pages/CourseDashboard/index";
import ModulesProvider from "context/module_context";
import Thanks from "Pages/Thanks";

function PrivateRoutesWrapper({ children }) {
  return isAuthenticated() ?
    children
    :
    <Redirect to="/login" />
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter >
      <Switch>
        <Route path="/" exact render={(props) => <Home {...props} />} />
        <Route path="/cursos-professor" render={(props) => <Courses {...props} />} />
        <Route path="/palestras" render={(props) => <Lectures {...props} />} />
        <Route path="/livros" render={(props) => <Books {...props} />} />
        <Route path="/sobre" render={(props) => <About {...props} />} />
        <Route path="/login" render={(props) => <Login {...props} />} />
        <Route path="/obrigado-gestao-sala" render={(props) => <Thanks {...props} />} />
        <PrivateRoutesWrapper>
          <ModulesProvider>
            <Route path="/modulos" render={(props) => <CourseDashboard {...props} />} />
            <Route path="/selecionar-curso" render={(props) => <CourseSelection {...props} />} />
          </ModulesProvider>
        </PrivateRoutesWrapper>
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);


