

import React from 'react';
import { useHistory } from 'react-router';
import { Button, Col, Row } from 'reactstrap';



const depositions = [
    // {
    //     text: '"Sensacional sua palestra. meus sinceros parabéns! Foi muito bom dividir este palco com você professor."',
    //     author: "- Diogo Portugal - Humorista, São Paulo-SP"
    // },
    // {
    //     text: '"Foi uma honra estar presente na sua palestra professor Marcelo. Com certeza um grande momento pra quem ama a educação."',
    //     author: '- Profº Wesley Souza - Brasília - DF'
    // },
    // {
    //     text: '"Motivacional, reflexiva e prática. Que todos os professores pudessem ter uma palestra como essa em sala de aula. Necessitamos de teoria + prática!"',
    //     author: '- Profº Fernando Souza - Palhoça - SC'
    // },

    {
        text: '"Parabéns pelo excelente trabalho professor. Obrigada por compartilhar conosco de forma tão dinâmica e produtiva todo teu conhecimento. Te ouvir foi motivador."',
        author: "- Psicologa Sandra Signor - Entre Rios - RS"
    },

]

export default function LectureCTA() {
    const history = useHistory()
    return (
        <Row>
            <Col className="m-4" style={{ paddingRight: "14vw", paddingLeft: "14vw" }}>
                {/* <Row className="pl-4 pr-4" >
                <Col className="pt-2 pr-4 pl-4">
                    <div>
                        <h4>
                            {depositions[1].text}
                        </h4>
                        <h6 className="pt-3">
                            {depositions[1].author}
                        </h6>
                    </div>
                </Col>
                <Col className="pt-2 pr-4 pl-4">
                    <div>
                        <h4>
                            {depositions[2].text}
                        </h4>
                        <h6 className="pt-3">
                            {depositions[2].author}
                        </h6>
                    </div>
                </Col>
            </Row> */}
                <Row className="d-flex justify-content-center" >
                    <h2 className="font-weight-bold" style={{ textAlign: 'center' }}>
                        "SENSACIONAL"
                    </h2>
                </Row>


                <Row>
                    <Col className="pr-4 pl-4" style={{ textAlign: "center" }}>
                        <h2  >
                            {depositions[0].text}
                        </h2>
                        <h6 className="pt-3">
                            {depositions[0].author}
                        </h6>
                    </Col>
                </Row>
                <Row className="mt-4 pt-4 mb-4 pb-4 d-flex justify-content-center" >
                    <Button onClick={() => history.push("/palestras")} className="btn-round" size="lg" >
                        Conheça as Palestras
                    <i class="fa fa-arrow-right"></i>
                    </Button>
                </Row>
            </Col>
        </Row>
    )
}