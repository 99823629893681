import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useMediaQuery } from 'react-responsive'

export default function HomeParallax() {

  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <section className="d-flex justify-content-center align-items-center" style={{
      width: "100vw",
      height: "100vh",
      position: "relative",
      overflow: "hidden",
    }}>
      <div
        style={{
          // transform: `translateY(${offset * 0.5}px)`,
          backgroundImage: `url(${!isMobile ? require("assets/img/professor-marcelo/thumbnail.jpeg") : require( "assets/img/professor-marcelo/thumbnail-mobile.jpeg")})`,
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          top: 0,
          left: 0,
          width: " 100%",
          height: " 100%",
          objectFit: "cover",
          zIndex: "-1"
        }}
        data-parallax={true}
      />

      {/* <div style={{
        width: " 100%",
        height: " 100%",
        objectFit: "cover",
        backgroundColor: "black",
        opacity: .7,
      }}> */}
      <Col md="12" className="d-flex flex-column justify-content-center h-100" style={{ width: "100vw" }}>
        <Row className="d-flex justify-content-center w-100">

          {/* <div className="mr-4" style={{border: "2px solid #fff", width: "36vh", height: "36vh", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
              <h5 className="text-white font-weight-bold" >
                PALESTRANTE
              </h5>
              <img style={{width: "14vw"}} src={require('../../../assets/img/professor-marcelo/tedx-logo.png')} />
            </div> */}
          {/* <div  className="ml-4" style={{border: "2px solid #fff", width: "36vh", height: "36vh", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
              <h5 className="text-white font-weight-bold text-center p-3" >
                DOUTORANDO EM EDUCAÇÃO CIENTÍFICA E TÉCNOLÓGICA
              </h5>
             </div> */}

        </Row>
        {/* <h1  style={{ fontWeight: "500" }}>ACREDITE NO PODER DA EDUCAÇÃO</h1>
          <h5  style={{ fontWeight: "500" }}>-Frase de efeito sinistra</h5> */}
      </Col>
      {/* </div> */}

    </section>
  );
}