import { loadCSVasJSON } from 'services/csv_loader';



export async function handleLogin({ email, password }) {
    try {
        // const response = await fetch(BACKEND_URL+"/auth/local", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json"
        //     },
        //     body: JSON.stringify({
        //         identifier: email,
        //         password: password
        //     })
        // });
        // const responseJson = await response.json();

        // if(!responseJson.jwt) throw responseJson


        // localStorage.setItem("token", JSON.stringify(responseJson.jwt))
        // localStorage.setItem("user", JSON.stringify(responseJson.user.id))

        // return {
        //     success: true,
        //     data: responseJson
        // }

        const response = await loadCSVasJSON("Banco de Dados - Usuários.csv")
        const usuario = response.find(el => el.email === email && el.senha === password)

        if (usuario) {
            localStorage.setItem("user", JSON.stringify(usuario))

            return {
                success: true,
                data: usuario
            }
        }

        return {
            success: false,
            error: Error("Credenciais invalidadas")
        }
    } catch (error) {
        return {
            success: false,
            error
        }
    }
}